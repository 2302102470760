import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LabComponent } from './lab/lab.component';
import { AccessionComponent } from './accession/accession.component';
import { LabResolverService } from './lab/lab-resolver.service';
import { AccessionResolver } from './accession/accession.resolver';
import { QueueAccessionResolver } from './accession/queue-accession.resolver';

const routes: Routes = [
  {
    path: `labs/:lab`,
    component: LabComponent,
    resolve: {
      lab: LabResolverService,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'accessions/:accessionId',
        component: AccessionComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          accession: AccessionResolver,
        },
      },
      {
        path: 'queues/:queueType',
        component: AccessionComponent,
        resolve: {
          accession: QueueAccessionResolver,
        },
        pathMatch: 'full',
        runGuardsAndResolvers: 'always',
      },
      // Default route to first accession in FULL queue
      {
        path: '**',
        redirectTo: 'queues/FULL',
      },
      {
        path: 'queues/**',
        redirectTo: 'queues/FULL',
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: 'queues/FULL',
        pathMatch: 'full',
      },
    ],
  },
  /**
   * Default lab to NOV now. This should eventually come from the user info
   */
  { path: '**', redirectTo: `labs/NOV` },
  { path: '', redirectTo: `labs/NOV`, pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
