<div luxLayout="column" luxFill class="spot-typography__text--body">
  <app-global-error-handler
    [loggedin]="loggedIn"
    (refresh)="handleRefresh($event)"
    luxLayout="row"></app-global-error-handler>

  <div luxLayout="row" luxFill>
    <router-outlet></router-outlet>
  </div>
</div>

<lux-modal-container-outlet> </lux-modal-container-outlet>
