import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Test } from '../../interfaces/test.interface';
import { TestComponent } from '../test/test.component';

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss'],
})
export class TestsComponent implements OnChanges {
  @Input()
  tests: Test[];

  @Input()
  accessionNumber: string;

  @Output()
  conflictError = new EventEmitter<true>();

  @ViewChild('testsWrapper')
  testsWrapper!: ElementRef;

  @ViewChildren('testItem')
  testItems: QueryList<TestComponent>;

  panels: Test[] = [];
  profiles: Test[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    let update = true;

    if (changes?.accessionNumber?.currentValue !== changes?.accessionNumber?.previousValue) {
      this.panels = [];
      this.profiles = [];
      update = false;
    }

    if (update) {
      // update the test comments directly to avoid a repaint
      this.handleTestUpdate(this.panels);
      this.handleTestUpdate(this.profiles);
    } else {
      changes.tests.currentValue.forEach((test) => {
        if (test.testType === 'PANEL') {
          this.panels.push(test);
        } else if (test.testType === 'PROFILE') {
          this.profiles.push(test);
        }
      });
    }
  }

  handleConflictError() {
    this.conflictError.emit(true);
  }

  focusFirstTestItem() {
    const hasFocusAbleTests = this.tests.filter((test) => {
      return !test.canceled;
    });

    // Return if the first focusable element is already focused
    if (
      (this.testItems.first.cmpWrapper.nativeElement.contains(document.activeElement) &&
        this.testItems.first.isExpanded) ||
      hasFocusAbleTests.length < 1
    ) {
      return;
    }

    this.testItems.first.cmpWrapper.nativeElement.focus();
  }

  private handleTestUpdate(testArr: Test[]) {
    testArr.forEach((test, index) => {
      const match = this.tests.find((existingTest) => existingTest.gdosId === test.gdosId);
      if (match) {
        test.comments = match.comments;
      } else {
        this.panels.splice(index, 1);
      }
    });
  }
}
