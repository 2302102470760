<div #testsWrapper id="tests-wrapper" data-cy="tests-wrapper">
  <div id="profiles" *ngIf="profiles?.length">
    <h3>{{ 'LABELS.PROFILES' | translate | uppercase }}</h3>
    <div data-cy="test" *ngFor="let test of profiles">
      <app-test #testItem (conflictError)="handleConflictError()" [test]="test"></app-test>
    </div>
  </div>

  <div id="panels" *ngIf="panels?.length">
    <h3>{{ 'LABELS.PANELS' | translate | uppercase }}</h3>
    <div data-cy="test" *ngFor="let test of panels">
      <app-test #testItem (conflictError)="handleConflictError()" [test]="test"></app-test>
    </div>
  </div>
</div>
