import { Result } from './result.interface';
import { Comment } from '@lims-common-ux/lux';

export enum AssayStatus {
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  RELEASED = 'RELEASED',
  VERIFIED = 'VERIFIED',
}

export interface Assay {
  acceptance?: {
    by: string;
    timestamp: string;
  };
  name: string;
  code: string;
  status: AssayStatus;
  acceptedResult?: Result;
  hasOtherResults: boolean;
  comments: Comment[];
  reference: string;
  unitOfMeasure?: string;
  expectedPerformingLab: string;
  goldListOrdinal: number;
  serviceCategoryCode: string;
  _links?: {
    addComment: { href: string; templated: true };
    markAsNoResult: { href: string };
    requestRepeat: { href: string };
  };
}
