<div data-cy="assay-card" luxLayout="column">
  <div
    #assayWrapper
    class="assay-line-item"
    data-cy="assay-card"
    luxLayout="row"
    luxLayoutAlign="center start"
    [attr.data-cy]="'assay-' + assay.code"
    [ngClass]="[assay.status || '', isSelected ? 'selected-assay' : '']"
    (mousedown)="handleMousedown($event)"
    (focusout)="handleFocusOut()"
    (focusin)="handleFocusIn()">
    <div luxFlex="40px" [ngSwitch]="assay.status">
      <span
        *ngSwitchCase="'RELEASED'"
        class="status-indicator assay-released-indicator"
        title="{{ 'LABELS.RELEASED' | translate }}">
        <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
          <title>{{ 'LABELS.RELEASED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#favorite-featured"></use>
        </svg>
      </span>

      <span
        *ngSwitchCase="'CANCELED'"
        data-cy="assay-canceled-indicator"
        class="status-indicator assay-canceled-indicator"
        title="{{ 'LABELS.CANCELED' | translate }}">
        <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
          <title>{{ 'LABELS.CANCELED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#cancel"></use>
        </svg>
      </span>

      <span
        *ngSwitchCase="'VERIFIED'"
        title="{{ 'LABELS.VERIFIED' | translate }}"
        data-cy="status-indicator"
        class="status-indicator">
        &nbsp;&nbsp;
      </span>

      <span
        *ngSwitchCase="'PENDING'"
        title="{{ 'LABELS.PENDING' | translate }}"
        data-cy="status-indicator"
        class="status-indicator">
        <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
          <title>{{ 'LABELS.PENDING' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#time-clock"></use>
        </svg>
      </span>

      <span
        *ngSwitchCase="'READY_FOR_REVIEW'"
        title="{{ 'LABELS.READY_FOR_REVIEW' | translate }}"
        data-cy="status-indicator"
        class="status-indicator">
        <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
          <title>{{ 'LABELS.READY_FOR_REVIEW' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#alert-notification"></use>
        </svg>
      </span>

      <span *ngSwitchDefault data-cy="status-indicator" class="status-indicator">
        <span>&nbsp;&nbsp;</span>
      </span>
    </div>

    <div luxFlex="200px" class="assay-name" data-cy="assay-name">
      <a href="javascript:void(0);" #assayName>
        <span
          [class]="assay.status === 'CANCELED' ? 'canceled' : ''"
          class="assay-name-display"
          data-cy="assay-name-display"
          >{{ assay.name }}</span
        >
        <!-- TRANSFER SCENARIO: PENDING RESULT -->
        <span
          *ngIf="assay.status === 'PENDING' && lab.id !== assay.expectedPerformingLab && !assay.acceptedResult"
          data-cy="assay-expected-lab"
          class="lab-abbreviation">
          ({{ assay.expectedPerformingLab }})
        </span>

        <!-- TRANSFER SCENARIO: RESULT RECIEVED (INCLUDING NO RESULT) -->
        <span
          *ngIf="
            assay.status !== 'PENDING' &&
            assay.acceptedResult?.performingLabId &&
            lab.id !== assay.acceptedResult?.performingLabId
          "
          data-cy="assay-performing-lab"
          class="lab-abbreviation">
          ({{ assay.acceptedResult?.performingLabId }})
        </span>
      </a>
    </div>
    @if (assay.acceptedResult?.type && assay.acceptedResult?.type === ResultType.FREE_TEXT) {
      <div luxFlex="500px" data-cy="result" [ngClass]="assay.status" luxLayout="row" luxLayoutAlign="start start">
        <span
          *ngIf="assay.acceptedResult?.displayValues && assay.acceptedResult?.displayValues.length > 0"
          class="accepted-result"
          data-cy="free-text-result-wrapper"
          luxLayout="column"
          title="{{ assay.acceptedResult?.timestamp | datePipe: lab }}&nbsp;&nbsp;{{
            assay.acceptedResult?.enteredBy
          }}">
          <span data-cy="free-text-result" class="free-text-result" *ngFor="let displayValue of assay.acceptedResult.displayValues">
            {{ displayValue }}
          </span>
        </span>
      </div>
    } @else {
      <div luxFlex="200px" data-cy="result" [ngClass]="assay.status" luxLayout="row" luxLayoutAlign="start center">
        <span
          *ngIf="assay.status === 'PENDING' && assay?.expectedPerformingLab !== originatingLab"
          class="transfer-indicator"
          data-cy="transfer-icon">
          <!-- transfer icon -- two arrows -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" aria-labelledby="title">
            <title>{{ 'LABELS.TRANSFER' | translate }}</title>
            <g>
              <polygon points="4 4 11 4 11 2 4 2 4 0 0 3 4 6 4 4" />
              <polygon points="8 8 1 8 1 10 8 10 8 12 12 9 8 6 8 8" />
            </g>
          </svg>
        </span>

        <lux-clinical-reference-indicator
          *ngIf="assay.acceptedResult?.relevanceIndicator === 'LOW'"
          [indicator]="assay.acceptedResult?.relevanceIndicator"></lux-clinical-reference-indicator>

        <span
          *ngIf="assay.acceptedResult?.displayValues && assay.acceptedResult?.displayValues.length > 0"
          class="accepted-result"
          luxLayout="column"
          title="{{ assay.acceptedResult?.timestamp | datePipe: lab }}&nbsp;&nbsp;{{
            assay.acceptedResult?.enteredBy
          }}">
          <span *ngFor="let displayValue of assay.acceptedResult.displayValues">
            {{ displayValue }}
          </span>
        </span>

        <lux-clinical-reference-indicator
          *ngIf="assay.acceptedResult?.relevanceIndicator === 'HIGH'"
          [indicator]="assay.acceptedResult?.relevanceIndicator"></lux-clinical-reference-indicator>

        <span
          *ngIf="assay.acceptedResult?.noResult"
          title="{{ 'LABELS.NO_RESULT' | translate }}"
          data-cy="no-result-indicator"
          class="status-indicator">
          <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
            <title>
              {{ 'LABELS.NO_RESULT' | translate }}&nbsp;&nbsp;{{
                assay.acceptedResult?.timestamp | datePipe: lab
              }}&nbsp;&nbsp;{{ assay.acceptedResult?.enteredBy }}
            </title>
            <use xlink:href="../../assets/icons/spot_icons.svg#blocked"></use>
          </svg>
        </span>
      </div>

      <div luxFlex="50px" align="center" data-cy="other-results">
        <span
          *ngIf="assay.hasOtherResults"
          title="{{ 'LABELS.HAS_PREVIOUS_RESULTS' | translate }}"
          class="previous-results-indicator">
          <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
            <title>{{ 'LABELS.HAS_PREVIOUS_RESULTS' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#list"></use>
          </svg>
        </span>
      </div>

      <div luxFlex="150px" align="left">
        <lux-clinical-reference luxFill [clinicalReference]="assay.reference"></lux-clinical-reference>
      </div>

      <div luxFlex="100px" align="left">
        <span data-cy="result-units">{{ assay.unitOfMeasure }}</span>
      </div>
    }

    <div luxFlex class="assay-comments-container" data-cy="assay-comments">
      <lux-comments
        (mousedown)="handleCommentSelection($event)"
        *ngIf="showCommentsModule()"
        [allowAdd]="showCommentsSearchInput()"
        [allowDelete]="assay.status !== 'PENDING' && assay.status !== 'CANCELED'"
        [commentsDataSource]="appStateService.commentsLink.href"
        [context]="assay"
        [debounceDuration]="200"
        [deleteExpandable]="true"
        [expandable]="true"
        [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
        [scrollOnSearchComplete]="true"
        [showBelowInput]="false"
        [showHeader]="false"
        (addComment)="onAddComment($event)"
        (removeComment)="onRemoveComment($event)"
        data-cy="assay-comments"
        #assayComments></lux-comments>
    </div>

    <div
      class="action-cell"
      luxLayoutAlign="space-around center"
      luxFlexOffset="8px"
      luxFlex="72px"
      luxLayout="row"
      data-cy="accession-actions">
      <div *ngIf="isSelected">
        <button
          [disabled]="!assay?._links?.markAsNoResult"
          (click)="noResult($event)"
          data-cy="assay-action-no-result"
          class="accession-actions-button">
          <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
            <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#blocked"></use>
          </svg>
        </button>
      </div>

      <div *ngIf="isSelected">
        <button
          [disabled]="!assay?._links?.requestRepeat"
          (click)="repeat($event)"
          data-cy="assay-action-repeat"
          class="accession-actions-button">
          <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
            <title>{{ 'LABELS.REPEAT' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#refresh-redo"></use>
          </svg>
        </button>
      </div>
    </div>

    <div class="exclude-cell" luxFlex="40px" (mouseenter)="selectAssay(true)">
      <label
        data-cy="assay-exclude-checkbox"
        title="{{ 'LABELS.RELEASE_INCLUDE_EXCLUDE' | translate }}"
        *ngIf="assay.status !== 'PENDING' && assay.status !== 'RELEASED' && assay.status !== 'CANCELED'"
        class="spot-form__checkbox cl-checkbox-toggle-focus">
        <input
          type="checkbox"
          class="spot-form__checkbox-input"
          (change)="handleExcludeCheckbox($event)"
          [checked]="!excluded" />
        <span class="spot-form__checkbox-inner">
          <span class="spot-form__checkbox-visual">
            <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
              <title>{{ 'LABELS.RELEASE_INCLUDE_EXCLUDE' | translate }}</title>
              <use xlink:href="../../assets/icons/spot_icons.svg#checkmark"></use>
            </svg>
          </span>
        </span>
      </label>
    </div>
  </div>
</div>
