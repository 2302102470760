import { inject, Injectable, InjectionToken } from '@angular/core';
import { StaticAppData } from './interfaces/application-data.interface';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PageResource } from './interfaces/pageResource.interface';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { EnvironmentService, FeaturesService, LabsService, LoggerService, UserService } from '@lims-common-ux/lux';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const pageResourcePath = '/index';

export const PAGE_RESOURCE = new InjectionToken<PageResource>('pageResource', {
  factory(): PageResource {
    const service = inject(ApplicationInitService);
    return service.staticAppData.pageResource;
  },
  providedIn: 'root',
});

export const STATIC_APP_DATA = new InjectionToken<StaticAppData>('staticAppData', {
  factory(): StaticAppData {
    const service = inject(ApplicationInitService);
    return service.staticAppData;
  },
  providedIn: 'root',
});

@Injectable({
  providedIn: 'root',
})
export class ApplicationInitService {
  // tslint:disable-next-line:variable-name
  private _staticAppData = {} as StaticAppData;

  get appEnvironment(): { production: boolean } {
    return environment;
  }

  constructor(
    private http: HttpClient,
    private labsService: LabsService,
    private userService: UserService,
    private featuresService: FeaturesService,
    private envService: EnvironmentService,
    private broadcaster: MsalBroadcastService,
    private loggerService: LoggerService
  ) {}

  /**
   * Promise used here because it's required by Angular's initialization code.
   */
  initialize(): Promise<StaticAppData> {
    this._staticAppData = {} as StaticAppData;

    return firstValueFrom(
      this.broadcaster.inProgress$.pipe(
        filter((progress) => progress === InteractionStatus.None),
        switchMap(() => {
          return this.http.get<PageResource>(pageResourcePath).pipe(
            switchMap((pageResource: PageResource) => {
              this._staticAppData.pageResource = pageResource;

              return forkJoin([
                this.labsService.getLabs(pageResource._links.labs),
                this.userService.getUser(pageResource._links.user),
                this.featuresService.getFeatures(pageResource._links.features),
                this.envService.getEnvironment(pageResource._links.info),
                this.appEnvironment.production ? this.loggerService.init() : of({}),
              ]);
            }),
            tap((forkResp: any[]) => {
              this._staticAppData.labs = forkResp[0];
              this._staticAppData.currentUser = forkResp[1];
              this._staticAppData.environment = forkResp[3]?.environment;
            }),
            map(() => this._staticAppData)
          );
        })
      )
    );
  }

  get staticAppData(): StaticAppData {
    return this._staticAppData;
  }
}
