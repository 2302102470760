import { Accession } from './../interfaces/accession';
import { Lab } from '@lims-common-ux/lux';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Assay } from '../interfaces/assay.interface';

export class LoadedAccessionInfo {
  accession: Accession;
  resultAccession: AccessionHeader;
  assays: Assay[];
}
