import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestsComponent } from './tests/tests.component';
import { TranslateModule } from '@ngx-translate/core';
import { LUX, LuxLayoutModule } from '@lims-common-ux/lux';
import { TestComponent } from './test/test.component';

@NgModule({
  imports: [CommonModule, TranslateModule, LuxLayoutModule, LUX],
  declarations: [TestsComponent, TestComponent],
  exports: [TestsComponent],
})
export class TestModule {}
