import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  AccessionModule,
  ApplicationPipesModule,
  DataResourcesModule,
  FeaturesModule,
  FlyoutModule,
  GlobalErrorHandlerModule,
  GlobalErrorHandlerService,
  LabNotesComponent,
  LabRequestInterceptor,
  LUX,
  LuxLayoutModule,
  SamplesModule,
  SnackbarModule,
} from '@lims-common-ux/lux';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { AppStateService } from './app-state.service';
import { AppRoutingModule } from './app-routing.module';
import { AccessionComponent } from './accession/accession.component';
import { ApplicationInitService } from './application-init.service';
import { StaticAppData } from './interfaces/application-data.interface';
import { LabResolverService } from './lab/lab-resolver.service';
import { AccessionResolver } from './accession/accession.resolver';
import { QueueAccessionResolver } from './accession/queue-accession.resolver';
import { CustomerMessagesModule } from './customer-messages/customer-messages.module';
import { AccessionService } from './accession/accession.service';
import { AssayComponent } from './assay/assay.component';
import { TestModule } from './test/test.module';
import { QueueService } from './accession/queue.service';
import { LabModule } from './lab/lab.module';
import {
  MsalBroadcastService,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { filter } from 'rxjs/internal/operators/filter';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/internal/operators/take';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

declare let CLIENT_ID: string;
declare let TENANT_ID: string;
declare let PROTECTED_RESOURCES: [string, string[]][];

function securityInitializer(broadcaster: MsalBroadcastService, service: MsalService): () => Observable<any> {
  return () => {
    // this is required to the service to complete its initialization. We need this to be done in an app initializer
    // because we often have data we need to retrieve that is protected, and this will take care of making sure those
    // calls are properly authenticated.
    service.handleRedirectObservable().subscribe(() => {
      // we don't care about the result value here, just let the library do its thing
    });

    return broadcaster.inProgress$.pipe(
      filter((progress) => progress === InteractionStatus.None),
      take(1) // the observable never completes normally, and we only need this to be hit once
    );
  };
}

const PROTECTED = new Map<string, Array<string>>(PROTECTED_RESOURCES);

function applicationDataInitFactory(service: ApplicationInitService): () => Promise<StaticAppData> {
  return (): Promise<StaticAppData> => service.initialize();
}

@NgModule({
  declarations: [AppComponent, AccessionComponent, AssayComponent],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    LUX,
    LuxLayoutModule,
    FlyoutModule,
    DataResourcesModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: CLIENT_ID, // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/' + TENANT_ID + '/',
          redirectUri: '/',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(PROTECTED),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AccessionModule,
    AppRoutingModule,
    RouterModule.forRoot([], { useHash: true, onSameUrlNavigation: 'reload' }),
    SnackbarModule,
    ApplicationPipesModule,
    CustomerMessagesModule,
    TestModule,
    GlobalErrorHandlerModule,
    SamplesModule,
    LabModule,
    FeaturesModule,
    LabNotesComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: securityInitializer,
      deps: [MsalBroadcastService, MsalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: applicationDataInitFactory,
      deps: [ApplicationInitService],
      multi: true,
    },
    AppStateService,
    AccessionService,
    TranslatePipe,
    { provide: 'Window', useValue: window },
    { provide: 'Document', useValue: document },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LabRequestInterceptor,
      multi: true,
    },
    ApplicationInitService,
    LabResolverService,
    AccessionResolver,
    QueueAccessionResolver,
    QueueService,
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useExisting: GlobalErrorHandlerService },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
