export interface Result {
  enteredBy: string;
  performingLabId: string;
  timestamp: string;
  displayValues: string[];
  relevanceIndicator: string;
  noResult?: boolean;
  type?: ResultType;
}

export enum ResultType {
  FREE_TEXT = 'FreeTextResult',
}
