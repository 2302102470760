<div id="context-wrapper" luxLayout="row" flex>
  <div luxLayout="column" flex style="min-width: 100%; width: 100%">
    <div luxLayout="row">
      <lux-snackbar #snackbarComponent>
        {{ 'ERRORS_AND_FEEDBACK.NEWER_RESULTS_AVAILABLE' | translate }}
        <button
          (click)="reloadAccession(accession)"
          tabindex="-1"
          data-cy="reload-button"
          class="spot-button spot-button--secondary conflict-reload-button">
          {{ 'LABELS.RELOAD' | translate }}
        </button>
      </lux-snackbar>
    </div>

    <div luxLayout="row" luxFill *ngIf="accession && accession.accessionId && lab">
      <div id="accession-details" luxFlex="300px" luxLayout="column">
        <div id="accession-details-top">
          <h1>{{ 'HOME.TITLE' | translate }}</h1>

          <!-- SAMPLES -->
          <lux-samples [samples]="accession.samples"></lux-samples>
          <!-- /SAMPLES -->
        </div>

        <!-- CUSTOMER MESSAGES -->
        <app-customer-messages
          *ngIf="accession.customerMessages && accession.customerMessages.length > 0"
          [customerMessages]="accession.customerMessages"></app-customer-messages>
        <!-- /CUSTOMER MESSAGES -->

        <!-- TESTS -->
        <app-tests
          *ngIf="accession?.orderedParentTests?.length"
          #panelsAndProfiles
          [accessionNumber]="accession.accessionNumber"
          [tests]="accession.orderedParentTests"></app-tests>
        <!-- /TESTS -->
      </div>

      <!-- SERVICE CATEGORIES AND ASSAYS -->
      <div id="service-categories-wrapper" luxFlex luxLayout="column">
        <div luxLayout="row" luxLayoutGap="8px" id="accession-released-indicator-wrapper" *ngIf="isAccessionReleased()">
          <span
            id="accession-released-indicator"
            data-cy="accession-released-indicator"
            title="{{ 'LABELS.RELEASED' | translate }}">
            <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
              <title>{{ 'LABELS.RELEASED' | translate }}</title>
              <use xlink:href="../../assets/icons/spot_icons.svg#favorite-featured"></use>
            </svg>
          </span>
          {{ 'LABELS.RELEASED' | translate }}
        </div>

        <!-- SERVICE CATEGORIES -->
        <div luxLayout="row" *ngFor="let serviceCategory of accession.serviceCategories">
          <div *ngIf="serviceCategory.assays && serviceCategory.assays.length > 0" luxLayout="column" luxFlex luxFill>
            <!-- SERVICE CATEGORY HEADER -->
            <div class="service-category-header" luxLayout="row" luxFlex luxLayoutAlign="space-between center">
              <span class="service-category-name">{{ serviceCategory.displayName }}</span>
              <a
                href="javascript:void(0);"
                data-cy="service-category-accordian-toggle"
                class="service-category-accordian-toggle"
                #lineItem
                [ngClass]="serviceCategory.expanded ? 'expanded' : 'collapsed'"
                (click)="handleServiceCategoryAccordionToggle(serviceCategory)">
                <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
                  <title>{{ 'LABELS.EXPAND' | translate }}</title>
                  <title>{{ 'LABELS.COLLAPSE' | translate }}</title>
                  <use xlink:href="../../assets/icons/spot_icons.svg#caret-up"></use>
                </svg>
              </a>
            </div>
            <!-- /SERVICE CATEGORY HEADER -->

            <div luxLayout="row" class="service-category-assays-wrapper">
              <div luxLayout="column" style="min-width: 100%" *ngIf="serviceCategory.expanded">
                <!-- ASSAYS HEADER -->
                <div luxLayout="row" class="assays-header">
                  <span luxFlex="40px" luxFlexAlign="center center"> </span>
                  <span luxFlex="200px">{{ 'LABELS.ASSAY' | translate }}</span>
                  <span luxFlex="200px" align="left">{{ 'LABELS.RESULT' | translate }}</span>
                  <span luxFlex="50px" align="right"></span>
                  <span luxFlex="150px" align="left">{{ 'LABELS.REFERENCE' | translate }}</span>
                  <span luxFlex="100px" align="left">{{ 'LABELS.UNITS' | translate }}</span>
                  <span luxFlex="400px">{{ 'LABELS.COMMENTS' | translate }}</span>
                  <span luxFlex="72px">&nbsp;</span>
                  <span class="exclude-cell" align="right" luxFlex style="padding-right: 20px">
                    <!-- show if at least one service category assay is releasable -->
                    <label
                      data-cy="service-category-exclude-checkbox"
                      title="{{ 'LABELS.RELEASE_INCLUDE_EXCLUDE' | translate }}"
                      *ngIf="hasReleasableAssays(serviceCategory.assays) > 0"
                      class="spot-form__checkbox cl-checkbox-toggle-focus">
                      <input
                        type="checkbox"
                        class="spot-form__checkbox-input"
                        [attr.data-service-category]="serviceCategory.code.toUpperCase()"
                        [checked]="getServiceCategoryExcludeTogglePresentation(serviceCategory.assays)"
                        (click)="handleServiceCategoryExcludeToggle($event, serviceCategory.assays)" />

                      <span class="spot-form__checkbox-inner">
                        <span class="spot-form__checkbox-visual">
                          <!-- CHECKED: show if all checkable assays are checked -->
                          <svg
                            *ngIf="getServiceCategoryExcludeTogglePresentation(serviceCategory.assays) === 'CHECKED'"
                            class="spot-icon spot-form__checkbox-checked-icon"
                            aria-labelledby="title">
                            <title>{{ 'LABELS.RELEASE_INCLUDE_EXCLUDE' | translate }}</title>
                            <use xlink:href="../../assets/icons/spot_icons.svg#checkmark"></use>
                          </svg>
                          <!-- INTERMEDIATE: show if some checkable assays are checked -->
                          <svg
                            *ngIf="
                              getServiceCategoryExcludeTogglePresentation(serviceCategory.assays) === 'INTERMEDIATE'
                            "
                            style="display: inline-block"
                            class="spot-icon spot-form__checkbox-indeterminate-icon"
                            aria-labelledby="title">
                            <title>{{ 'LABELS.RELEASE_INCLUDE_EXCLUDE' | translate }}</title>
                            <use xlink:href="../../assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
                          </svg>
                        </span>
                      </span>
                    </label>
                  </span>
                </div>
                <!-- /ASSAYS HEADER -->
                <!-- ASSAYS -->
                <app-assay
                  #assay
                  #lineItem
                  *ngFor="let assay of serviceCategory.assays"
                  [assay]="assay"
                  [excluded]="isAssayExcluded(assay)"
                  [isSelected]="selectedAssay && selectedAssay.code === assay.code"
                  (exclude)="handleAssayExcludeToggle($event)"></app-assay>
                <!-- /ASSAYS -->
              </div>
            </div>
          </div>
        </div>
        <!-- /SERVICE CATEGORIES -->
      </div>
      <!-- /SERVICE CATEGORIES AND ASSAYS -->
    </div>

    @if ((!accession || !accession.accessionId) && !appStateService.loading) {
      <div luxLayout="row">
        <div data-cy="queue-empty-message" id="queue-empty-message" luxLayoutAlign="center center">
          {{ 'ERRORS_AND_FEEDBACK.QUEUE_EMPTY' | translate }}
        </div>
      </div>
    } @else if (accession) {
      <!-- z-index required because the position is fixed, which resets the stacking order and we want lab notes in
      particular to live above everything else, but below items like the spinner and flyout -->
      <div
        class="accession-actions"
        luxFlex
        luxLayout="row"
        luxLayoutAlign="end end"
        luxLayoutGap="13px"
        style="z-index: 8000">
        <lux-lab-notes
          #labNotes
          [getLabNotesLink]="appStateService.getLabNotesLink"
          [addLabNoteLink]="appStateService.addLabNoteLink"
          dragBoundary="#context-wrapper"
          [accessionId]="accession.accessionId"
          [lab]="lab"
          [context]="'ACCESSION_REVIEW'"
          [labNotesPosition]="{ x: -600, y: -600 }"></lux-lab-notes>
        <button
          data-cy="release-button"
          class="spot-button spot-button--primary release-button"
          *ngIf="accession"
          [disabled]="
            !accession?._links?.release ||
            appStateService.loading ||
            !isAccessionReleasable(accession.assays) ||
            (labNotes?.hasUnsavedLabNotes$ | async)
          "
          (click)="releaseAccession()">
          {{ 'LABELS.RELEASE' | translate }}
        </button>

        <button
          data-cy="queue-next"
          #nextInQueueButton
          *ngIf="appStateService.queueWorkspace && !appStateService.queueEmptyMessageVisible"
          [disabled]="appStateService.loading || (labNotes?.hasUnsavedLabNotes$ | async)"
          (click)="getNextAccessionInQueue($event)"
          class="spot-button next-link spot-button--secondary spot-button--with-icon"
          title="{{ 'LABELS.SKIP' | translate }}">
          <span *ngIf="appStateService.workQueue" class="queue-next-text" data-cy="queue-next-text">{{
            'LABELS.QUEUE_' + appStateService.workQueue.type | translate
          }}</span>

          <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--right queue-next-icon">
            <title>{{ 'LABELS.SKIP' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#back"></use>
          </svg>
        </button>
      </div>
    }
  </div>
</div>
