<p
  class="customer-message"
  data-cy="customer-message"
  luxLayout="row"
  [ngClass]="[isExpanded ? 'expanded' : '']"
  (click)="toggleExpanded()">
  {{ customerMessage }}
  <a href="javascript:void(0);" #setExpandedToggle (focus)="setExpanded(true)" (blur)="setExpanded(false)"
    ><span>...</span></a
  >
</p>
