import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Lab, Link, LocaleService, ResultsDataResourcesService, WorkQueuesService } from '@lims-common-ux/lux';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';
import { AppStateService } from '../app-state.service';
import { PageResource } from '../interfaces/pageResource.interface';
import { PAGE_RESOURCE } from '../application-init.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class LabResolverService {
  private readonly resultLink: Link;

  constructor(
    private appStateService: AppStateService,
    private translate: TranslateService,
    private workQueueService: WorkQueuesService,
    private resultResourceService: ResultsDataResourcesService,
    private titleService: Title,
    @Inject(PAGE_RESOURCE) resource: PageResource,
    private localeService: LocaleService
  ) {
    this.resultLink = resource._links.results;
  }

  /**
   * Sets the lab, but also loads the result data resource. This is required for the header search
   * to work correctly as it needs the links from there.
   *
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{ lab: Lab; locale: string }> | Observable<never> {
    const labId = route.paramMap.get('lab');
    const lab = this.appStateService.labs.find((listLab) => listLab.id === labId);
    this.localeService.setUrlLocale(route.queryParams.locale);

    this.appStateService.lab = lab;

    return this.resultResourceService.getResultsDataResource(this.resultLink, lab).pipe(
      switchMap((resource: ResultsDataResource) => {
        this.appStateService.configureSharedLinks(resource);
        return this.translate.use(this.localeService.selectedLocale);
      }),
      switchMap(() => {
        return this.workQueueService.getWorkQueues();
      }),
      map((workQueuesResponse) => {
        this.appStateService.queues = workQueuesResponse;
        this.titleService.setTitle(this.appStateService.defaultPageTitle);
        return { lab, locale: this.localeService.selectedLocale };
      })
    );
  }
}
