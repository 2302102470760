import { Injectable } from '@angular/core';
import { AppStateService } from '../app-state.service';
import { HttpClient } from '@angular/common/http';
import { AccessionService } from './accession.service';
import { LoadedAccessionInfo } from './loaded-accession.class';
import { AuditService, Link } from '@lims-common-ux/lux';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

interface QueueLinks {
  _links: {
    next: Link;
    self: Link;
  };
}

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  constructor(
    public appStateService: AppStateService,
    private accessionService: AccessionService,
    private auditService: AuditService,
    private http: HttpClient
  ) {}

  advanceQueue(queueUrl: Link): Observable<LoadedAccessionInfo> {
    const loadedAccession = new LoadedAccessionInfo();

    this.appStateService.queueEmptyMessageVisible = false;

    return this.http.get<QueueLinks>(queueUrl.href).pipe(
      catchError((err) => {
        this.appStateService.queueEmptyMessageVisible = true;
        return of(err);
      }),
      map((queue) => {
        if (queue?._links?.next) {
          const queueNextUrl = queue._links.next;
          this.appStateService.queueNextUrl = queueNextUrl;
          return queue;
        } else {
          this.appStateService.queueNextUrl = null;
          this.appStateService.queueEmptyMessageVisible = true;
          return null;
        }
      }),
      switchMap((queueResponse) => {
        if (!queueResponse) {
          return of(null);
        } else {
          return this.accessionService.loadAccessionHeader(queueResponse?.accessionId);
        }
      }),
      switchMap((accessionHeader) => {
        if (accessionHeader) {
          return this.accessionService.loadAccession(accessionHeader?.id);
        } else {
          return of(null);
        }
      }),
      switchMap((accession) => {
        if (accession) {
          loadedAccession.accession = this.appStateService.accession;
          loadedAccession.resultAccession = this.appStateService.accessionHeader;

          this.appStateService.queueWorkspace = true;
          loadedAccession.assays = this.appStateService.accession?.assays;
          this.auditService.fireAccessionLoaded(loadedAccession.accession.accessionId);
        } else {
          loadedAccession.accession = null;
          loadedAccession.resultAccession = null;
          this.appStateService.queueNextUrl = null;
          this.appStateService.queueEmptyMessageVisible = true;
        }
        return of(loadedAccession);
      })
    );
  }
}
