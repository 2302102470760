<div luxLayout="row">
  <lux-header
    #header
    [env]="env"
    [accession]="appStateService.accessionHeader"
    [availableLabs]="labs"
    [currentLab]="currentLab"
    [user]="user"
    [configuration]="{
      accessionSearch: accessionSearchLink,
      accessionLinks: true,
      attachments: appStateService.accessionHeader?._links?.attachments,
      accessionAdvancedSearch: appStateService.accessionAdvancedSearchLink,
      localeSelector: true,
    }"
    [context]="'ACCESSION_REVIEW'"
    (updateLab)="handleUpdateLab($event)"></lux-header>
</div>

<div id="lab-page" luxLayout="row" *ngIf="currentLab">
  <lux-flyout
    luxFlex="40px"
    [unitaryNavigationItems]="flyoutNavigation"
    [currentNavItem]="currentWorkQueue | workQueueToFlyoutNavigationItem"
    [showWorklists]="false"
    [showSampleStorage3rdPartyReferrals]="false"
    (flyoutItemSelected)="changeWorkspace($event)"
    (helpModalOpen)="helpModalOpen()"
    #flyout>
  </lux-flyout>

  <router-outlet></router-outlet>
</div>

<app-help-modal #helpModalContainer></app-help-modal>
