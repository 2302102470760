<div
  #testWrapper
  *ngIf="test"
  class="test"
  [attr.data-cy]="'test-' + test.code"
  [class.expanded]="isExpanded"
  [tabindex]="!isExpanded && !test.canceled ? 1 : -1"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)">
  <span
    *ngIf="test.canceled"
    data-cy="test-canceled-indicator"
    class="status-indicator test-canceled-indicator"
    title="{{ 'LABELS.CANCELED' | translate }}">
    <svg aria-labelledby="title" class="spot-icon spot-button__icon spot-button__icon--left">
      <title>{{ 'LABELS.CANCELED' | translate }}</title>
      <use xlink:href="../../../assets/icons/spot_icons.svg#cancel"></use>
    </svg>
  </span>
  <div class="test-code" data-cy="test-code" [class]="test.canceled ? 'canceled' : ''">
    {{ test.code }}
  </div>
  <div class="test-name" data-cy="test-name">{{ test.name | titlecase }}</div>
  <div class="panel-comments-wrapper" data-cy="panel-comments" (focusin)="suppressFocusIn($event)">
    <lux-comments
      [allowAdd]="isExpanded && !!test._links?.addComment"
      [allowDelete]="!test.canceled"
      [commentsDataSource]="appStateService.commentsLink.href"
      [context]="{ comments: test.comments }"
      [debounceDuration]="200"
      [deleteExpandable]="true"
      [expandable]="true"
      [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
      [showHeader]="false"
      (addComment)="onAddComment($event)"
      (removeComment)="onRemoveComment($event)"
      #testComments></lux-comments>
  </div>
</div>
