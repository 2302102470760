import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerMessagesComponent } from './customer-messages.component';
import { CustomerMessageComponent } from './customer-message/customer-message.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CustomerMessagesComponent, CustomerMessageComponent],
  exports: [CustomerMessagesComponent],
  imports: [CommonModule, TranslateModule],
})
export class CustomerMessagesModule {}
