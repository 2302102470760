import { Pipe, PipeTransform } from '@angular/core';
import { FlyoutNavigationItem, WorkQueue } from '@lims-common-ux/lux';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '../../app-state.service';

@Pipe({
  name: 'workQueueToFlyoutNavigationItem',
})
export class WorkQueueToFlyoutNavigationItemPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private appStateService: AppStateService
  ) {}

  transform(value: WorkQueue): FlyoutNavigationItem | null {
    return value
      ? {
          id: value.type,
          name: this.translate.instant(`LABELS.QUEUE_${value.type.toUpperCase()}`),
          buttonLink: { href: `/labs/${this.appStateService.lab.id}/queues/${value.type.toUpperCase()}` },
        }
      : null;
  }
}
