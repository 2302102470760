import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Accession } from '../interfaces/accession';
import { AccessionService } from './accession.service';
import { AppStateService } from '../app-state.service';
import { switchMap, tap } from 'rxjs/operators';
import { AuditService } from '@lims-common-ux/lux';

@Injectable({
  providedIn: 'root',
})
export class AccessionResolver {
  constructor(
    private appStateService: AppStateService,
    private accessionService: AccessionService,
    private auditService: AuditService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Accession> {
    const accessionId = route.paramMap.get('accessionId');

    if (!accessionId) {
      throw new Error('No reachable accession found');
    }

    this.appStateService.currentAssay = of(null);
    this.appStateService.queueWorkspace = false;
    this.appStateService.queueEmptyMessageVisible = false;
    this.appStateService.workQueue = null;

    return this.accessionService.loadAccessionHeader(accessionId).pipe(
      switchMap(() => this.accessionService.loadAccession(accessionId)),
      tap(() => this.auditService.fireAccessionLoaded(accessionId))
    );
  }
}
