import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabComponent } from './lab.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { LabResolverService } from './lab-resolver.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  FlyoutModule,
  HeaderModule,
  KeyboardHelpCategoryModule,
  LuxLayoutModule,
  ModalContainerModule,
} from '@lims-common-ux/lux';
import { RouterModule } from '@angular/router';
import { WorkQueueToFlyoutNavigationItemPipe } from './workQueueToFlyoutNavigationItem/work-queue-to-flyout-navigation-item.pipe';

@NgModule({
  declarations: [LabComponent, HelpModalComponent, WorkQueueToFlyoutNavigationItemPipe],
  imports: [
    CommonModule,
    TranslateModule,
    ModalContainerModule,
    HeaderModule,
    FlyoutModule,
    RouterModule,
    LuxLayoutModule,
    KeyboardHelpCategoryModule,
  ],
  providers: [LabResolverService, WorkQueueToFlyoutNavigationItemPipe],
})
export class LabModule {}
