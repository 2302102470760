import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Lab, LabsService, Link, User, WorkQueue } from '@lims-common-ux/lux';
import { STATIC_APP_DATA } from './application-init.service';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Accession } from './interfaces/accession';
import { share, switchMap } from 'rxjs/operators';
import { Assay } from './interfaces/assay.interface';
import { TranslateService } from '@ngx-translate/core';
import { StaticAppData } from './interfaces/application-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  accessionSub = new BehaviorSubject<Accession>(null);
  private resultsResource: ResultsDataResource = null;

  private _lab: Lab = null;
  private _accession: Accession;
  private _accessionHeader: AccessionHeader;
  private _loading = true;
  private currentAssaySub = new BehaviorSubject<Observable<Assay>>(of(null));
  private isInitialized = false;
  public focusFirstAssayEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public accessionChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  workQueue: WorkQueue;
  queueWorkspace = false;
  queueEmptyMessageVisible: boolean;
  queues: WorkQueue[];
  queueNextUrl: Link;

  constructor(
    @Inject('Document') private document: any,
    @Inject(STATIC_APP_DATA) private staticData: StaticAppData,
    private labsService: LabsService,
    private translate: TranslateService
  ) {}

  configureSharedLinks(resource: ResultsDataResource) {
    this.resultsResource = resource;
  }

  triggerFocusFirstAssay() {
    this.focusFirstAssayEvent.emit(true);
  }

  triggerAccessionChange() {
    this.accessionChangedEvent.emit(true);
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;

    if (!this.isInitialized && !this._loading) {
      this.isInitialized = true;
    }

    if (this._loading) {
      this.document.getElementById('app-loader').style.display = 'block';

      if (this.isInitialized && this.lab) {
        this.document.getElementById('app-loader').classList.add('waiting-on-interaction');
      }
    } else {
      setTimeout(() => {
        this.document.getElementById('app-loader').style.display = 'none';
      }, 100);
    }
  }

  get currentAssay(): Observable<Assay> {
    return this.currentAssaySub.asObservable().pipe(switchMap((obs) => obs));
  }

  set currentAssay(obs: Observable<Assay>) {
    this.currentAssaySub.next(obs.pipe(share()));
  }

  get labs(): Lab[] {
    return this.staticData.labs;
  }

  set lab(lab: Lab) {
    this._lab = lab;
    this.labsService.currentLab = lab;
    // changing lab will always require the accession and queue to be cleared, even if you want to view the same
    // accession, it will still need to get reloaded.
    this.accession = null;
    this.accessionHeader = null;
    this.workQueue = null;
  }

  get lab(): Lab {
    return this._lab;
  }

  get env(): string {
    return this.staticData.environment;
  }

  get defaultPageTitle(): string {
    let title = this.translate.instant('HOME.TITLE');

    if (this.staticData.environment === 'uat' || this.staticData.environment === 'exp') {
      title += ` (${this.staticData.environment})`;
    }

    return title;
  }

  get accessionHeader(): AccessionHeader {
    return this._accessionHeader;
  }

  set accessionHeader(accessionHeader: AccessionHeader) {
    this._accessionHeader = accessionHeader;
  }

  get accession(): Accession {
    return this._accession;
  }

  set accession(accession: Accession) {
    this._accession = accession;
    this.accessionSub.next(accession);
  }

  get user(): User {
    return this.staticData.currentUser;
  }

  get accessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionSearch');
  }

  get commentsLink(): Link {
    return this.verifyAndGetResultLinks('comments');
  }

  get addLabNoteLink(): Link {
    return this.verifyAndGetResultLinks('addLabNote');
  }

  get getLabNotesLink(): Link {
    return this.verifyAndGetResultLinks('getLabNotes');
  }

  get accessionAdvancedSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionAdvancedSearch');
  }

  private verifyAndGetResultLinks(linkName: string): Link {
    const link = this.resultsResource?._links[linkName];
    if (link == null) {
      throw new Error(
        'Result links not set when looking for link ' +
          linkName +
          '. Please use `configureSharedLinks` to set this data appropriately.'
      );
    } else {
      return link;
    }
  }
}
